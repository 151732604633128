import React from "react"
import Layout from "../components/Layout"
import indexPic from "../images/fjwb.jpg"
import ContactBlock from "../components/contactBlock"

export default function Home() {
  return (
    <Layout>
      <div className="page">
        <div className="hero-block-alt">
          <div className="img-container">
            <div className="headshot"></div>
            <div className="badge"></div>
          </div>
          <div className="sub">
            <h2>Andrea C. Ferster</h2>
            <h1>Attorney at Law</h1>
          </div>
        </div>
        <div className="main-content">
          <div className="shout">
            <p>
              <span>"A relentless advocate,"</span> Andrea Ferster has been
              practicing law in D.C. for over 30 years, focusing on
              environmental and historic preservation litigation and tax exempt
              organizations.
            </p>
          </div>
        </div>

        <div className="main-content">
          <div className="block">
            <h2>Our law offices</h2>
            <div className="block-sub">
              <p>
                Established in 1992, the Law Offices of Andrea Ferster focuses
                on environmental and historic preservation litigation and
                advocacy, and nonprofit tax and corporate matters.
              </p>
            </div>
          </div>

          <div className="block">
            <h2>Our Focus</h2>

            <div className="block-sub">
              <ul>
                <li>
                  <span>Federal environmental and historic preservation</span>{" "}
                  laws litigation and advocacy relating to highways and other
                  federally-assisted development projects.
                </li>
                <li>
                  <span>Advocacy</span> before D.C. and New York courts and
                  agencies on zoning, historic preservation, and environmental
                  impact issues.
                </li>
                <li>
                  <span>Nonprofit law</span> providing a range of advice and
                  assistance on nonprofit tax and corporate matters.
                </li>
                <li>
                  <span>Trails</span> legal advice and representation on trail
                  issues.
                </li>
              </ul>
              <div className="img-block right">
                <img src={indexPic} alt="" />
                <div className="cred">
                  Frank J Wood Bridge in Brunswick, Maine - Photo credit:
                  Penninah Graham
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <h2>Andrea C. Ferster</h2>
            <div className="block-sub">
              <div className="p-block">
                <p>
                  Andrea C. Ferster is an attorney in private practice in
                  Washington, D.C. and New York. Her law practice focuses on
                  litigation to enforce federal environmental and historic
                  preservation laws, transportation advocacy, tax exempt
                  organizations, enforcement of local zoning and land use
                  ordinances, and trail and greenway planning.
                </p>
                <p>
                  She has lectured frequently on historic preservation law and
                  serves as General Counsel to the Rails to Trails Conservancy.
                  She received her B.A. from Sarah Lawrence College in 1979 and
                  her J.D. from the George Washington University National Law
                  Center in 1984.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          {/* Contact */}
          <ContactBlock />
        </div>
      </div>
    </Layout>
  )
}
